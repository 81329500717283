import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

import {
  accountResetPasswordRequest,
  accountResetPasswordReceive,
} from "../actions";
import NavigateButton from "./NavigateButton";
import { navigate } from "gatsby-link";

/**
 * Form to change the account password
 * @param {any} dispatch - dispatches actions to trigger state changes
 * @param {int} timeStamp - timestamp in milliseconds
 * @param {string} signature - token signature string for validation against the server
 * @param {int} account_user_id - current user id
 * @param {any} account_reset_password_response - response from the server
 */
const AccountResetPassword = ({
  dispatch,
  timeStamp,
  signature,
  account_user_id: user_id,
  account_reset_password_response,
}) => {
  const { t } = useTranslation();

  const [formState, setFormState] = useState({
    new_password: "",
    new_password_confirm: "",
    error: null,
  });

  const validation = () => {
    const { new_password, new_password_confirm } = formState;

    // validate confirm password field
    if (new_password_confirm !== new_password) {
      setFormState({
        ...formState,
        error: t("Confirm password doesn't match new password"),
      });
      return false;
    }

    return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validation()) {
      dispatch(
        accountResetPasswordRequest({
          password: formState.new_password,
          timestamp: timeStamp,
          user_id,
          signature,
        })
      );
    }
  };

  const handleChange = (event, key) =>
    setFormState({
      ...formState,
      [key]: event.target.value,
      error: null,
    });

  const beforeNavigate = () => {
    dispatch(accountResetPasswordReceive({}));
  };

  const { success, data } = account_reset_password_response || {};

  // success response from the server, force logout to log back in with new password
  if (success === true) {
    navigate("/account/logout/?reset=1");
  }

  function renderChangePasswordForm() {
    return (
      <>
        {success === false && data && data.detail && (
          <Alert variant="danger">{data.detail}</Alert>
        )}
        <Form.Group controlId="formBasicPassword2">
          <Form.Label>
            <Trans>New Password</Trans>
          </Form.Label>
          <Form.Control
            type="password"
            autoComplete="new-password"
            isInvalid={success === false && data && data.password}
            value={formState.new_password}
            onChange={(e) => handleChange(e, "new_password")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.password &&
              data.password.length &&
              data.password.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="formBasicPassword3">
          <Form.Label>
            <Trans>Confirm New Password</Trans>
          </Form.Label>
          <Form.Control
            type="password"
            autoComplete="new-password"
            isInvalid={!!formState.error}
            value={formState.new_password_confirm}
            onChange={(e) => handleChange(e, "new_password_confirm")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {data &&
              data.new_password_confirm &&
              data.new_password_confirm.length &&
              data.new_password_confirm.join(" ")}
          </Form.Control.Feedback>
        </Form.Group>

        {formState.error && <Alert variant="danger">{formState.error}</Alert>}

        <Form.Group className="mt-4">
          <Button variant="secondary" type="submit">
            <Trans>Submit</Trans>
          </Button>
          <NavigateButton
            wrapperClass="d-inline"
            buttonClass="btn btn-outline-secondary ml-3"
            path="/account/"
            buttonText={t("Cancel")}
            beforeNavigate={beforeNavigate}
          />
        </Form.Group>
      </>
    );
  }

  return (
    <div className="px-md-4 account-container">
      <Row>
        <Col>
          <h2 className="py-4">
            <FontAwesomeIcon icon={faUserCogReg} className="mr-3" fixedWidth />
            <Trans>Account profile</Trans>
          </h2>
          <h6 className="mb-4 h5">
            <Trans>Change Account Password</Trans>
          </h6>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} noValidate>
        {renderChangePasswordForm()}
      </Form>
    </div>
  );
};

AccountResetPassword.propTypes = {
  dispatch: PropTypes.any,
  timeStamp: PropTypes.number,
  signature: PropTypes.string,
  account_user_id: PropTypes.number,
  account_reset_password_response: PropTypes.object,
};

export default AccountResetPassword;
