import React from "react";
import PropTypes from "prop-types";
import { Form, Button, Col, Row, Alert } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCog as faUserCogReg } from "@fortawesome/pro-regular-svg-icons";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import {
  accountSendResetPasswordRequest,
  accountSendResetPasswordReceive,
} from "../actions";
import NavigateButton from "./NavigateButton";

const AccountSendResetPassword = ({
  dispatch,
  account_email,
  account_send_reset_password_response,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (event) => {
    event.preventDefault();
    dispatch(
      accountSendResetPasswordRequest({
        login: account_email,
      })
    );
  };

  const beforeNavigate = () => {
    dispatch(accountSendResetPasswordReceive({}));
  };

  const { success, data } = account_send_reset_password_response || {};

  const renderSuccessAlert = () => (
    <>
      <p className="alert alert-success">
        Please check your email for the link to reset your password.
      </p>
      <NavigateButton
        buttonClass="btn btn-outline-secondary"
        wrapperClass="account-container-btn"
        path="/account/"
        buttonText={t("Back to Account Profile")}
        beforeNavigate={beforeNavigate}
      />
    </>
  );

  const renderChangePasswordForm = () => {
    if (success === true) {
      return renderSuccessAlert();
    }
    return (
      <>
        {success === false && data && data.detail && (
          <Alert variant="danger">{data.detail}</Alert>
        )}
        <div className="alert alert-primary">
          <Trans>
            Click the button below to request to change your current account
            password. You will receive an email with a time-sensitive link to
            where you can change your password.
          </Trans>
        </div>

        <Form.Group className="mt-4">
          <Button onClick={handleSubmit} variant="primary" type="submit">
            <Trans>Request Change Password</Trans>
          </Button>
          <NavigateButton
            buttonClass="btn btn-sm btn-link mt-3 pl-1 text-muted"
            path="/account/"
            buttonText={t("Cancel and go back")}
            beforeNavigate={beforeNavigate}
          />
        </Form.Group>
      </>
    );
  };

  return (
    <div className="px-md-4 account-container">
      <Row>
        <Col>
          <h2 className="py-4">
            <FontAwesomeIcon icon={faUserCogReg} className="mr-3" fixedWidth />
            <Trans>Account profile</Trans>
          </h2>
          <h6 className="mb-4 h5">
            <Trans>Change Account Password Request</Trans>
          </h6>
        </Col>
      </Row>
      {renderChangePasswordForm()}
    </div>
  );
};

AccountSendResetPassword.propTypes = {
  dispatch: PropTypes.any,
  account_email: PropTypes.string,
  account_send_reset_password_response: PropTypes.object,
};

export default AccountSendResetPassword;
