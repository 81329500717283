import { graphql } from "gatsby";
import React from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap } from "@fortawesome/pro-regular-svg-icons";
import Layout from "../../components/Layout";
import SidebarAccount from "../../components/SidebarAccount";
import AccountSendResetPassword from "../../components/AccountSendResetPassword";
import AccountResetPassword from "../../components/AccountResetPassword";
import NavLink from "../../components/NavLink";

const ResetPassword = (props) => {
  const [timeStamp] = useQueryParam("timestamp", NumberParam);
  const [signature] = useQueryParam("signature", StringParam);

  const renderResetPasswordComponent = () => {
    if (timeStamp && signature) {
      return (
        <AccountResetPassword
          {...props}
          timeStamp={timeStamp}
          signature={signature}
        />
      );
    }

    return <AccountSendResetPassword {...props} />;
  };

  return (
    <Layout
      pageName="Reset password"
      secondaryNav={
        <NavLink
          linkPath="/"
          linkText="Map"
          linkIcon={<FontAwesomeIcon icon={faMap} size="lg" />}
        />
      }
    >
      <Container fluid>
        <SidebarAccount {...props} />
        {renderResetPasswordComponent()}
      </Container>
    </Layout>
  );
};

export default connect((state) => state)(ResetPassword);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
